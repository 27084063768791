import createMedia, { createDevice } from '@baevra/media'

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
  xxl: 5120
}

const {
  xs,
  sm,
  lg,
  xl,
  xxl
} = breakpoints

const devices = {
  mobile: createDevice(xs, sm),
  tablet: createDevice(sm, lg),
  desktop: createDevice(lg, xl),
  largeDesktop: createDevice(xl,xxl),
  extra: createDevice(xxl)
}

const media = createMedia(breakpoints, devices)
const { up, down, between, only } = media

export {
  breakpoints,
  devices,
  up,
  down,
  between,
  only
}
