import { createGlobalStyle } from 'styled-components'
import { up, down } from 'src/utils/media'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Cormorant';
    src: url(${require('src/static/fonts/cormorant-medium.woff2')}) format('woff2'),
         url(${require('src/static/fonts/cormorant-medium.woff')}) format('woff'),
         url(${require('src/static/fonts/cormorant-medium.woff2')}) format('otf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cormorant';
    src: url(${require('src/static/fonts/cormorant-mediumitalic.woff2')}) format('woff2'),
         url(${require('src/static/fonts/cormorant-mediumitalic.woff')}) format('woff'),
         url(${require('src/static/fonts/cormorant-mediumitalic.otf')}) format('otf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cormorant';
    src: url(${require('src/static/fonts/cormorant-semibold.woff2')}) format('woff2'),
         url(${require('src/static/fonts/cormorant-semibold.woff')}) format('woff'),
         url(${require('src/static/fonts/cormorant-semibold.otf')}) format('otf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cormorant';
    src: url(${require('src/static/fonts/cormorant-bold.woff2')}) format('woff2'),
         url(${require('src/static/fonts/cormorant-bold.woff')}) format('woff'),
         url(${require('src/static/fonts/cormorant-bold.otf')}) format('otf');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${require('src/static/fonts/montserrat-medium.woff2')}) format('woff2'),
         url(${require('src/static/fonts/montserrat-medium.woff')}) format('woff'),
         url(${require('src/static/fonts/montserrat-medium.ttf')}) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${require('src/static/fonts/montserrat-semibold.woff2')}) format('woff2'),
         url(${require('src/static/fonts/montserrat-semibold.woff')}) format('woff'),
         url(${require('src/static/fonts/montserrat-semibold.ttf')}) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  :root {
    --vh: 1vh;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* cursor: default; */
  }

  html {
    font-size: 62.5%;
    font-family: ${props => props.theme.fontFamily.primary}, Arial, Helvetica, sans-serif;
    color: ${props => props.theme.colors.bodyTextDark};
    scroll-behavior: smooth;
  }

  body {
    background-color: ${props => props.theme.colors.backgroundLight};
    font-family: ${props => props.theme.fontFamily.primary};
    color: ${props => props.theme.colors.bodyTextDark};
    font-size: 1.6rem;
    letter-spacing: 0;
    line-height: 1.35;
    font-weight: 500;
    position: relative;
    transition: overflow .3s ease-out;

    &.ReactModal__Body--open {
      overflow: hidden;
      transition: overflow .3s ease-out .3s;
    }

    ${up('tablet')} {
      font-size: 1.7rem;
    }

    ${up('desktop')} {
      font-size: 1.7rem;
    }

    ${up('largeDesktop')} {
      font-size: 2.7rem;
    }
  }

  body > .ReactModalPortal {
    opacity: 0;
    transition: opacity .3s ease-out;
  }

  body.ReactModal__Body--open > .ReactModalPortal {
    opacity: 1;
  }

  a {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    outline: none;
    text-decoration: none;

    ${up('tablet')} {
      /* cursor: default; */
    }

    &:hover, &:active {
      /* cursor: default; */
      color: inherit;
    }
  }

  .interactive, .image-zoom {
    ${up('tablet')} {
      /* cursor: none!important; */
    }
  }

  .mobile {
    ${up('tablet')} {
      display: none!important;
    }
  }

  .desktop {
    display: none!important;

    ${up('tablet')} {
      display: block!important;
    }
  }

  #nprogress{
    & > .bar {
      background: ${props => props.theme.colors.progressBar}!important;

      & > .peg {
        display: none!important;
        box-shadow: none!important;
      }
    }
  }

  .modal-overlay {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${props => props.theme.colors.backgroundDark};
    z-index: 9000;
    padding: 0;
  }

  .modal-window {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0;
    position: relative;
    background-color: ${props => props.theme.colors.backgroundDark};
    outline: none;
  }

  ${down('desktop')} {
    .modal-content {
      overflow-y: scroll;
    }
  }

  /*===== CUSTOM SCROLLBAR =====*/
  /* For Google Chrome */
  ::-webkit-scrollbar {
      width: 7px;
  }

  ::-webkit-scrollbar-thumb {
      background: ${props => props.theme.colors.progressBar};
      border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
      background: ${props => props.theme.colors.separator[100]};
  }

  /* For Internet Explorer */
  body {
    scrollbar-face-color: ${props => props.theme.colors.progressBar};
    scrollbar-track-color: ${props => props.theme.colors.separator[100]};
  }
  /*==== END OF SCROLLBAR STYLING =====*/

  main.shrinked {
    ${up('tablet')} {
      max-height: calc(100vh - 7rem);
    }

    ${up('desktop')} {
      max-height: calc(100vh - 9.5rem);
    }

    ${up('largeDesktop')} {
      max-height: calc( 100vh - 10rem);
    }
  }
`

export default GlobalStyle
