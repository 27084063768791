import React from 'react'
import { MediaProvider } from 'src/utils/useMedia'
import { ThemeProvider } from 'styled-components'
import theme from 'src/theme'
import * as media from 'src/utils/media'
import GlobalStyle from 'src/theme/globalStyle'

export const wrapRootElement = ({ element }) => {
  return (
    <MediaProvider value={{ media }}>
      <ThemeProvider theme={theme}>
        {element}
      </ThemeProvider>
    </MediaProvider>
  )
}

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <GlobalStyle />
      {element}
    </>
  )
}
