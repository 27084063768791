import { useState, useEffect } from 'react'

// This variable will be true once the server-side hydration is completed.
let hydrationCompleted = false

function useMediaQuery(
  queryInput: string,
  ssrMatchMedia: Function
) {
  const query = queryInput.replace(/^@media( ?)/m, '')
  const supportMatchMedia =
    typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined'

  const [match, setMatch] = useState(() => {
    if ((hydrationCompleted) && supportMatchMedia) {
      return window.matchMedia(query).matches
    }
    if (ssrMatchMedia) {
      return ssrMatchMedia(query).matches
    }

    // Once the component is mounted, we rely on the
    // event listeners to return the correct matches value.
    return false
  })

  useEffect(() => {
    let active = true
    hydrationCompleted = true

    if (!supportMatchMedia) {
      return undefined
    }

    const queryList = window.matchMedia(query)
    const updateMatch = () => {
      // Workaround Safari wrong implementation of matchMedia
      // TODO can we remove it?
      // https://github.com/mui-org/material-ui/pull/17315#issuecomment-528286677
      if (active) {
        setMatch(queryList.matches)
      }
    }
    updateMatch()
    queryList.addListener(updateMatch)
    return () => {
      active = false
      queryList.removeListener(updateMatch)
    }
  }, [query, supportMatchMedia])

  return match
}

export function testReset() {
  hydrationCompleted = false
}

export default useMediaQuery
