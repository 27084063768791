import { createContext, useContext } from 'react'
import useMediaQuery from './useMediaQuery'
import MobileDetect from 'mobile-detect'
import mediaQuery from 'css-mediaquery'

const Context = createContext({})
Context.displayName = 'Media'
export const MediaProvider = Context.Provider

const formatQuery = (query: string) => {
  return query.replace('@media ', '')
}

const getQuery = (
  queryOrDevice: any,
  media: any
) => {
  if (typeof media === 'undefined') {
    return queryOrDevice
  }

  const device = media.devices[queryOrDevice]

  if (device) {
    return media.only(queryOrDevice)
  }

  return queryOrDevice
}

const useMedia = (queryOrDevice: any) => {
  const { userAgent, media } = useContext(Context) as any
  const query = getQuery(queryOrDevice, media)
  const formattedQuery = formatQuery(query)

  const ssrMatchMedia = (query: string) => {
    const md = new MobileDetect(userAgent)
    const type = 'screen'

    let width = `${media.devices.desktop && media.devices.desktop.min}px` || '1920px'

    if (md.mobile()) {
      width = `${media.devices.mobile && media.devices.mobile.max - 1}px` || '599px'
    }

    if (md.tablet()) {
      width = `${media.devices.tablet && media.devices.tablet.min}px` || '1279px'
    }

    return {
      // @ts-ignore-start
      matches: mediaQuery.match(`screen and ${query}`, { type, width })
      // @ts-ignore-end
    }
  }


  const isMediaQuery = useMediaQuery(formattedQuery, ssrMatchMedia)

  return isMediaQuery
}

export default useMedia
