// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-expositions-tsx": () => import("./../src/pages/expositions.tsx" /* webpackChunkName: "component---src-pages-expositions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-templates-announcement-tsx": () => import("./../src/templates/announcement.tsx" /* webpackChunkName: "component---src-templates-announcement-tsx" */),
  "component---src-templates-announcements-tsx": () => import("./../src/templates/announcements.tsx" /* webpackChunkName: "component---src-templates-announcements-tsx" */),
  "component---src-templates-archive-tsx": () => import("./../src/templates/archive.tsx" /* webpackChunkName: "component---src-templates-archive-tsx" */),
  "component---src-templates-archives-tsx": () => import("./../src/templates/archives.tsx" /* webpackChunkName: "component---src-templates-archives-tsx" */),
  "component---src-templates-exposition-tsx": () => import("./../src/templates/exposition.tsx" /* webpackChunkName: "component---src-templates-exposition-tsx" */),
  "component---src-templates-news-tsx": () => import("./../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */)
}

