import { DefaultTheme } from 'styled-components'

const myTheme: DefaultTheme = {
  borderRadius: '5px',
  videoSize: 'calc((100vw - 14rem)/3)',

  containerWidth: {
    sm: 'calc(100vw - 4rem)',
    md: 'calc(100vw - 4rem)',
    lg: 'calc(100vw - 14rem)',
    xl: 'calc(100vw - 14rem)'
  },

  columnGutter: {
    sm: 16,
    md: 16,
    lg: 20,
    xl: 20
  },

  fontFamily: {
    primary: 'Cormorant',
    secondary: 'Montserrat'
  },

  colors: {
    backgroundLight: '#F4F3F2',
    backgroundDark: '#181818',
    bodyTextLight: '#FFFFFF',
    bodyTextDark: '#090909',
    separator: {
      100: '#D3D3D1',
      300: '#B9B9B5',
      700: '#404040'
    },
    button: '#7D7D7C',
    subtitle: '#757575',
    notifier: '#EF653C',
    progressBar: '#A0A0A0',
    cursorDark: '#231F20'
  }
}

export default myTheme
